import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Grayson is a multidisciplinary artist who works primarily in technology, light, and performance.`}</p>
    <p>{`He is a southern-transplant living in the greater Seattle area. He moved to Seattle on a whim when I was 21,
and it was the craziest and best decision of his life.`}</p>
    <p>{`By day he is a software engineer, and a creative maker by passion. When he's not working, he spends a good bit of his
time designing new things, tinkering with electronics, and trying his damnedest to incite productive mayhem in those
around him.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      